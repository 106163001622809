


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

    html {
      font-family: 'Segoe UI',sans-serif;
    }

    .btn{
        @apply rounded-full !important;
    }

    .btn-sm{
        @apply text-xs leading-none !important;
    }

    .btn.btn-disabled{
      @apply text-accent !important;
    }

    .btn-primary,.btn-secondary {
        color:white !important;
        
    }

    .step-primary::after{
      color:white !important;
    }
    
    
    @keyframes shake {
        10%, 90% {
          transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
          transform: translate3d(2px, 0, 0);
        }
      
        30%, 50%, 70% {
          transform: translate3d(-4px, 0, 0);
        }
      
        40%, 60% {
          transform: translate3d(4px, 0, 0);
        }
      }
}

